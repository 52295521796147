<template>

<app-page theme="purple" close-button="Recommendation" :size-content="true" class="select">

		<app-title text="How to use an inhaler" :is-uppercase="true" :hide-line="true" class="select-title" />

		<div v-html="content.select.use" />

		<div class="select-type" v-for="type in types" :key="type.id">

			<h3>{{ type.name }}</h3>

			<div class="select-type-products" :class="{'is-many': products(type.id).length > 3, 'is-some': products(type.id).length === 3}">

				<app-button class="select-type-products-item" theme="purple" v-for="product in products(type.id)" :key="product.id" :text="product.name" v-on:click="onProductClick(product.id)" />

			</div>

		</div>

		<div class="select-more" v-if="otherTypes.length" v-on:click="showMore = !showMore">
			<template v-if="showMore">hide</template><template v-if="!showMore">view</template> other types of inhaler
		</div>

		<template v-if="showMore">

		<div class="select-type" v-for="type in otherTypes" :key="type.id">

			<h3>{{ type.name }}</h3>

			<div class="select-type-products" :class="{'is-many': products(type.id).length > 3, 'is-some': products(type.id).length === 3}">

				<app-button class="select-type-products-item" theme="purple" v-for="product in products(type.id)" :key="product.id" :text="product.name" v-on:click="onProductClick(product.id)" />

			</div>

		</div>

		</template>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			showMore: false
		}

	},

	computed: {

		content: function() {

			return this.$store.getters['data'].recommendations

		},

		recommendation: function() {

			return this.$store.getters['recommendation']

		},

		otherTypes: function() {

			return this.$_.filter(this.$store.getters['data'].inhalers.types, function(type) {

				return !this.$_.contains(this.recommendation.types, type.id)

			}.bind(this))

		},

		types: function() {

			return this.$_.filter(this.$store.getters['data'].inhalers.types, function(type) {

				return this.$_.contains(this.recommendation.types, type.id)

			}.bind(this))

		}

	},

	methods: {

		products: function(type) {

			return this.$_.where(this.$store.getters['data'].inhalers.products, {
				type: parseInt(type)
			})

		},

		onProductClick: function(type) {

			this.$store.commit('selection', parseInt(type))
			
			this.$router.push({
				name: 'Recommendation'
			})

		}

	}

}

</script>

<style scoped>

.select-title {
	width: 100%;
}

.select >>> p {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
	color: #fff;
}

.select >>> h2 {
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 20px;
	font-weight: 500;
	color: #fff;
	letter-spacing: 1.8px;
	text-transform: uppercase;
}

.is-mobile .select >>> h2 {
	text-align: center;
}

.select-more {
	text-align: center;
	width: 260px;
	margin: 40px auto;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	background-image: url(~@/assets/select.add.svg);
	background-position: 0px 50%;
	background-repeat: no-repeat;
	padding-left: 40px;
}                             

.select-type {
	padding: 60px 0px 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.is-mobile .select-type {
	padding: 20px 0px 0px 0px;
}

.select-type h3 {
	color: #fff;
	font-size: 30px;
	letter-spacing: 3.6px;
	font-weight: 300;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.is-mobile .select-type h3 {
	margin-bottom: 20px;
	text-align: center;
}

.select-type-products {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
}

.select-type-products-item {
	margin: 0px 5px 20px 5px;
	line-height: 22px;
	padding: 10px 10px;
	width: calc(50% - 10px);
}

.is-mobile .select-type-products-item {
	margin: 0px 0px 5px 0px;
	width: 100%;
}

.select-type-products.is-many {
	justify-content: flex-start;
}

.select-type-products.is-some .select-type-products-item {
	width: calc(33.3% - 10px);
}

.select-type-products.is-many .select-type-products-item {
	width: calc(25% - 10px);
}

.is-mobile .select-type-products.is-some .select-type-products-item,
.is-mobile .select-type-products.is-many .select-type-products-item {
	width: 100%;
}

</style>
